@import "../../app.scss";

.contact {
  height: 100%;
  max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 50px;
  @include mobile {
    height: auto;
    align-items: flex-start;
  }
    .blue {
    color: #00aaff;
  }

  .orange {
    color: #db492c;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .dropdown {
    position: relative;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    width: 100%;
  }

  .dropdown-content {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    padding: 10px 0;
  }

  .dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    color: white;
  }

  .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .dropdown-item.selected {
    background-color: rgba(255, 127, 0, 0.5);
  }

  .checkmark {
    font-size: 18px;
    color: #ffcc00;
  }

  .arrow {
    transition: transform 0.3s;
  }

  .arrow.up {
    transform: rotate(180deg);
  }

  .arrow.down {
    transform: rotate(0deg);
  }

  .message-field {
    transition: margin-top 0.3s ease;
  }

  .message-field.pushed {
    margin-top: 20px;
  }

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    h1 {
      font-size: 100px;
      line-height: 88px;
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;

    .phoneSvg {
      stroke: #db492c;
      position: absolute;
      margin: auto;
      z-index: -1;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        border-radius: 5px;
      }

      button {
        background-color: #f57c00;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          background-color: #db492c;
          transform: scale(1.05);
        }
      }
    }
  }

  // Mobile overrides
  @include mobile {
    width: 100%;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
    align-items: center; // Only affect mobile, keeps desktop unchanged

    .textContainer {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 5px;

      h1 {
        font-size: 24px;        // Smaller heading on mobile
        text-align: right;      // Right align heading on mobile
        align-self: flex-end;   // Position heading to the right
      }
    }

    .formContainer {
      padding: 20px;
      width: 100%;

      form {
        gap: 10px; // Slightly closer spacing on mobile

        input,
        textarea {
          padding: 10px; // Smaller input padding on mobile
        }

        button {
          padding: 8px 16px; // Smaller button padding on mobile
        }
      }
    }
  }
}
