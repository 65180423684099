@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

.animate-fade-in {
    animation: fadeIn 1.5s ease-in-out forwards;
}

.animate-bounce {
    animation: bounce 1.5s infinite;
}
