@import "../../app.scss";

.navbar {
  height: 100px;
  background-color: transparent; // Transparent background
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .wrapper {
    width: 100%;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include mobile {
      padding: 20px;
    }

    .logo-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;

      .logo {
        height: 50px; // Adjusted logo size for balanced appearance
        width: auto;
      }
    }

    .social {
      display: flex;
      gap: 20px;
      margin-left: auto;

      .icon {
        color: white;
        transition: color 0.3s;

        &:hover {
          color: #00aaff;
        }

        .social-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
