html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-x: hidden; /* Prevent horizontal scroll issues */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: #171717;
  color: lightgray;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  overflow: hidden;
}

@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1366px) {
    @content;
  }
}
