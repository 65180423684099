@import "../../app.scss";

.portfolio {
  position: relative;

  .progress {
    position: sticky;
    top: 0;
    left: 0;
    padding-top: 50px;
    text-align: center;
    color: #db492c;
    font-size: 70px;

    @include mobile {
      padding-top: calc(100vh - 150px);
      font-size: 34px;
    }

    .progressBar {
      height: 10px;
      background-color: white;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
      max-width: 1366px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
      }

      .imageContainer {
        flex: 1;
        height: 49%;

        @include mobile {
          width: 100%;
          max-height: 300px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mobile {
            object-fit: contain;
          }
        }
      }

      .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mobile {
          transform: none !important;
          padding: 10px 15px; /* Padding for mobile */
          align-items: center;
          text-align: center;
        }

        h2 {
          font-size: 70px; /* Large font size for desktop */
          color: #db492c; /* Brighter title color */
          font-weight: bold;

          @include mobile {
            font-size: 35px; /* Larger title font size for mobile */
          }
        }

        p {
          color: #f0f0f0; /* Light color closer to white for better readability */
          font-size: 22px; /* Increased font size for desktop */
          line-height: 1.8; /* Improved readability with better spacing */

          @include mobile {
            font-size: 20px; /* Larger font size for mobile */
            line-height: 1.6; /* Maintain good spacing */
            margin: 0 10px; /* Small margin for better mobile layout */
          }
        }

        button {
          background-color: #db492c;
          border: none;
          border-radius: 10px;
          padding: 12px 20px; /* Slightly larger button padding */
          width: 220px; /* Wider button for desktop */
          cursor: pointer;
          font-size: 18px;

          @include mobile {
            width: 180px; /* Adjusted width for mobile */
            font-size: 16px;
          }

          &:hover {
            background-color: #ff6048; /* Brighter hover effect */
            transform: scale(1.05); /* Subtle hover animation */
          }
        }
      }


    }
  }
}
